(function ($) {
  $(document).ready(function () {
    function getStorageKey(id) {
      return "SiteBanner_" + id + "_Dismiss";
    }

    // Called when a user closes a banner.
    function callback(event) {
      var button = event.currentTarget;
      var bannerId = button.getAttribute("data-id");
      var banner = document.getElementById("site-banner-" + bannerId);

      // The banner can only be closed once, so we don't need the click handler anymore.
      button.removeEventListener("click", callback);

      // Remove the banner from the page.
      banner.parentNode.removeChild(banner);

      // Make sure the banner doesn't re-appear when the page is re-loaded.
      sessionStorage.setItem(getStorageKey(bannerId), "true");
    }

    var bannersNodeList = document.querySelectorAll(".site-banner");
    var index = 0;
    var bannerId = 0;
    var button = null;

    for (index; index < bannersNodeList.length; index += 1) {
      bannerId = bannersNodeList[index].getAttribute("data-id");

      // Don't display banners which have been dismissed.
      if (sessionStorage.getItem(getStorageKey(bannerId))) {
        continue;
      }

      // Display the banner.
      bannersNodeList[index].setAttribute("aria-hidden", "false");

      // Add a click event the "dismiss" button, if it exists.
      button = document.querySelector(
        "#" + bannersNodeList[index].id + " .site-banner-close"
      );

      if (button) {
        // hide close button for browsers without sessionStorage compatibility
        if (!sessionStorage) {
          button.style.display = "none";
        } else {
          button.addEventListener("click", callback);
        }
      }
    }
    var $menu = $("#my-menu");
    if ($menu.hasClass("hideMaiENZ")) {
      $("#my-menu").mmenu(
        {
          // options
          offCanvas: {
            position: "right",
          },
        },
        {
          // configuration
          offCanvas: {
            pageSelector: ".container-fluid.page",
          },
        }
      );
    } else {
      $("#my-menu").mmenu(
        {
          // options
          offCanvas: {
            position: "right",
          },
          navbars: [
            {
              position: "top",
              content: [
                "<div class='header-maienz header-maienz--reversed'><a class='header-maienz__link' href='https://maienz.enz.govt.nz/'><span class='header-maienz__wrapper'><span class='header-maienz__menu'></span><span class='header-maienz__link-text'>MaiENZ</span><span class='header-maienz__open'></span></span></a></div>",
              ],
            },
          ],
        },
        {
          // configuration
          offCanvas: {
            pageSelector: ".container-fluid.page",
          },
        }
      );
    }

    $(".faqs__accordion-toggle").on("click", function () {
      $(".faqs__accordion-toggle").addClass("faqs__accordion-toggle--minus");
      if (
        $(this).parent().parent().parent().find(".panel-collapse.in").length
      ) {
        $(this).addClass("faqs__accordion-toggle--minus");
      } else {
        $(this).removeClass("faqs__accordion-toggle--minus");
      }
    });
  });
})(jQuery);

if (
  document.querySelector("footer") &&
  document.querySelector(".maienz-signup")
) {
  var waypoint = new Waypoint({
    element: document.querySelector("footer"),
    handler: function () {
      var maiEnzSignup = document.querySelector(".maienz-signup");
      if (maiEnzSignup.classList.contains("stuck")) {
        maiEnzSignup.classList.remove("stuck");
      } else {
        maiEnzSignup.classList.add("stuck");
      }
    },
    offset: "100%",
  });
}
if (
  document.querySelector(".maienz__whatis-wrapper") &&
  document.querySelector(".maienz-signup")
) {
  var waypoint2 = new Waypoint({
    element: document.querySelector(".maienz__whatis-wrapper"),
    handler: function () {
      var maiEnzSignup = document.querySelector(".maienz-signup");
      if (maiEnzSignup.classList.contains("stuck")) {
        maiEnzSignup.classList.remove("stuck");
      } else {
        maiEnzSignup.classList.add("stuck");
      }
    },
    offset: "100%",
  });
}

if (document.querySelector(".mediabox")) {
  MediaBox(".mediabox", { rel: "0" }); // Additional options
}
