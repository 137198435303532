(function ($) {
  $("html").on("click", ".news .more-articles", function (e) {
    $.get($(e.target).data("url"), function (data) {
      $(".article-load-more").replaceWith(data);
      //bit of a hack
      $(".last-item-count").text(
        $(".news-teaser-content").last().data("last-item")
      );
    });
  });
})(jQuery);
