(function($) {
    $('.signup-next').on('click', function(e){
        e.preventDefault();

       if(!$('#signup').val() || !validateEmail($('#signup').val())) {
           $('.email-help').removeClass('hidden');
           $('#signup').addClass('has-error');
       } else {
           $('.updates').addClass('is-active');
           $('.accordion').addClass('is-active');
           $('.email-help').addClass('hidden');
           $('#Email').val($("#signup").val());
           $('#Email').attr("type", "hidden");
           $("#Email").parent().addClass("hidden-field");
           $("#Email").parent().parent().closest(".mktoFormRow").addClass("hidden-field");
           $("#Company").parent().parent().closest(".mktoFormRow").addClass("company-field");
           $(".mktoLogicalField").parent().parent().closest(".mktoFormRow").addClass("agree-field");
           $(".mktoButtonWrap").children().removeClass("mktoButton");
           $(".mktoButtonWrap").children().addClass("chevron");
           $(".signup-extra-fields").children().first().addClass("form-content-row");
       }
    });

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function validateForm(){
        var valid = true;

        if (!validateEmail($('#signup').val())) {
            $('.email-help').removeClass('hidden');
            valid = false;
        } else {
            $('.email-help').addClass('hidden');
        }

        if (!$('#firstname').val()) {
            $('.firstname-help').removeClass('hidden');
            valid = false;
        } else {
            $('.firstname-help').addClass('hidden');
        }

        if (!$('#lastname').val()) {
            $('.lastname-help').removeClass('hidden');
            valid = false;
        } else {
            $('.lastname-help').addClass('hidden');
        }

        if (!$('#company').val()) {
            $('.company-help').removeClass('hidden');
            valid = false;
        } else {
            $('.company-help').addClass('hidden');
        }

        if ($('[name="optionsRadios"]:checked').length == 0) {
            $('.organisation-help').removeClass('hidden');
            valid = false;
        } else {
            $('.organisation-help').addClass('hidden');
        }

        if ($('[name="toc"]:checked').length == 0) {
            $('.toc-help').removeClass('hidden');
            valid = false;
        }else {
            $('.toc-help').addClass('hidden');
        }
        console.log('valid', valid);
        return valid;
    }
}(jQuery));
