(function($) {
    $(".about").on("change", "#country", function() {
        var countryID = $('#country').val();
        if (countryID) {
            $('#team').val("");
            $('.country-group').addClass('is-active');
            $('.team-group').removeClass('is-active');

            $.ajax({
                url: window.location.pathname + "/display-country",
                data: { countryID: countryID }
            }).done(function(data) {
                $('.contact-tab').html(data);
            });
        }
    });

    $(".about").on("change", "#team", function() {
        var teamID = $('#team').val();
        if (teamID) {
            $('#country').val("");
            $('.team-group').addClass('is-active');
            $('.country-group').removeClass('is-active');

            $.ajax({
                url: window.location.pathname + "/display-team",
                data: {teamID: teamID}
            }).done(function(data) {
                $('.contact-tab').html(data);
            });
        }
    });

    $(".about").on("click", ".location-row .show-email", function(e) {
        $.post( window.location.pathname + "/get-email", { cityID: $(e.target).data("city-id") }, function( data ) {
            $(e.target).replaceWith('<span><a href="mailto:' + data + '">' + data + '</a></span>');
        });
    });

    $(".about").on("click", ".show-contact-email", function(e) {
        $.post( window.location.pathname + "/get-email", { contactID: $(e.target).data("contact-id") }, function( data ) {
            $(e.target).replaceWith('<span><a href="mailto:' + data + '">' + data + '</a></span>');
        });
    });
}(jQuery));

