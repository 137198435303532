(function($) {
  $(document).ready(function() {

    //bit of a hack as silverstripe rewrites hash links
    $(".my-menu-link").attr("href", "#my-menu");

    // Homepage tabs
    $('ul.tabs').each(function(){
      // For each set of tabs, we want to keep track of
      // which tab is active and its associated content
      var $active, $content, $links = $(this).find('a');

      // If the location.hash matches one of the links, use that as the active tab.
      // If no match is found, use the first link as the initial active tab.
      $active = $($links.filter('[href="'+location.hash+'"]')[0] || $links[0]);
      $active.addClass('is-active');

      $content = $($active[0].hash);

      // Hide the remaining content
      $links.not($active).each(function () {
        $(this.hash).hide();
      });

      // Bind the click event handler
      $(this).on('click', 'a', function(e){
        // Make the old tab inactive.
        $active.removeClass('is-active');
        $content.hide();

        // Update the variables with the new link and content
        $active = $(this);
        $content = $(this.hash);

        // Make the tab active.
        $active.addClass('is-active');
        $content.show();

        // Prevent the anchor's default click action
        e.preventDefault();
      });
    });


  // Search box in header (mobile view )

    $('.open-search a').click(function(){
      $('.searchbox').toggleClass('is-active');
    });


   // Contact page accordions

    $('.about').on('click', '.contacts-accordion-heading', function(){
      $(this).next('.contacts-accordion').toggleClass('is-active');
      $(this).find('h4').toggleClass('is-active');
    });

  });


  /* search events header/form */

  $('.search-events-heading').click(function(){
      $('.search-events-heading').toggleClass('is-active');
      $('.search-events').toggleClass('is-active').slideToggle('slow');
    });


  /* search news header/form */

  $('.search-news-heading').click(function(){
      $('.search-news-heading').toggleClass('is-active');
      $('.search-news').toggleClass('is-active').slideToggle('slow');
    });


  // Custom form select

  // $('.form-group.custom select').customSelect();

  

}(jQuery));