var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useRef } from 'react';
import fetch from 'unfetch';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import './form-style.scss';
var ErrorMsg = function (_a) {
    var children = _a.children;
    return React.createElement("span", { className: "error-msg" },
        "This field is required",
        children);
};
var LeadForm = function () {
    var _a = useForm({
        mode: 'onBlur',
    }), register = _a.register, handleSubmit = _a.handleSubmit, watch = _a.watch, errors = _a.errors, setValue = _a.setValue;
    var _b = useState(false), successfulSubmission = _b[0], setSuccessfulSubmission = _b[1];
    var _c = useState([]), orgData = _c[0], setOrgData = _c[1];
    var _d = useState([]), industrySector = _d[0], setIndustrySectors = _d[1];
    var _e = useState(false), submissionError = _e[0], setSubmissionError = _e[1];
    var recaptchaRef = useRef(null);
    var watchOrganisation = watch('organisationType', ''); // you can supply default value as second argument
    useEffect(function () {
        fetch('/lead-form/organisationTypes')
            .then(function (r) { return r.json(); })
            .then(function (data) {
            setOrgData(data);
        });
    }, []);
    useEffect(function () {
        setValue('industrySector', '');
        var organisationData = orgData.find(function (org) {
            return org.type == watchOrganisation;
        });
        if (organisationData) {
            setIndustrySectors(organisationData.subtypes);
        }
    }, [watchOrganisation]);
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var token, submissionData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, recaptchaRef.current.executeAsync()];
                case 1:
                    token = _a.sent();
                    LoadAnimate();
                    submissionData = __assign(__assign({}, data), { munchkinId: Cookies.get('_mkto_trk'), captchaResponse: token });
                    fetch('/lead-form', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(submissionData),
                    })
                        .then(function (r) {
                        return r.json();
                    })
                        .then(function (response) {
                        if (response.hasOwnProperty('success') && response.success) {
                            setSuccessfulSubmission(true);
                            setCookie();
                        }
                        if (response.hasOwnProperty('error')) {
                            setSubmissionError(true);
                        }
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    /* form control functions */
    var _f = useState(true), active = _f[0], setActive = _f[1];
    var closeForm = function () {
        setActive(!active);
        setCookie();
    };
    /* minimum the popup form */
    var _g = useState(true), reveal = _g[0], setReveal = _g[1];
    var toggleClass = function () {
        setReveal(!reveal);
    };
    /* read more toggle */
    var _h = useState(false), readMore = _h[0], setReadMore = _h[1];
    var toggleAccordion = function () {
        setReadMore(!readMore);
    };
    /* Loading */
    var _j = useState(false), Loading = _j[0], setLoading = _j[1];
    var LoadAnimate = function () {
        setLoading(!Loading);
    };
    /* Set up and check cookies when the page load */
    var _k = useState(false), hasCookie = _k[0], setHasCookie = _k[1];
    useEffect(function () {
        var cookie = Cookies.get('hideLeadPopup');
        /* check cookie status */
        if (cookie) {
            setHasCookie(true);
        }
    }, []);
    var setCookie = function () {
        Cookies.set('hideLeadPopup', 'true');
    };
    return (React.createElement("aside", { className: 'lead-form-container' +
            (active && !hasCookie ? '' : ' hide') +
            (reveal ? '' : ' collapsed') },
        React.createElement("div", { className: "lead-form" },
            React.createElement("div", { className: "lead-form-header" },
                React.createElement("h4", null, "Stay informed"),
                React.createElement("div", { className: "control-bttns" },
                    React.createElement("div", { className: "minimize", onClick: function () {
                            toggleClass();
                        } },
                        React.createElement("span", { className: 'bar' + (reveal ? '' : ' collapsed') })),
                    React.createElement("span", { className: "close", onClick: function () {
                            closeForm();
                        } }))),
            React.createElement("div", { className: 'lead-form-content' + (reveal ? '' : ' hide') },
                !successfulSubmission && !submissionError && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: 'lead-form-content-description' + (reveal ? '' : ' hide') }, "For up to date information on international education in New Zealand."),
                    React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                        React.createElement("input", { name: "firstName", placeholder: "First name*", defaultValue: "", className: 'lead-form-control' + (errors.firstName ? ' error' : ''), ref: register({ required: true }) }),
                        errors.firstName && React.createElement(ErrorMsg, null),
                        React.createElement("input", { name: "lastName", placeholder: "Last name*", className: 'lead-form-control' + (errors.lastName ? ' error' : ''), ref: register({ required: true }) }),
                        errors.lastName && React.createElement(ErrorMsg, null),
                        React.createElement("input", { type: "email", name: "email", placeholder: "Email address*", className: 'lead-form-control' + (errors.email ? ' error' : ''), ref: register({
                                pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/i,
                                required: true,
                            }) }),
                        errors.email && (React.createElement(ErrorMsg, { children: ', with a valid email address' })),
                        React.createElement("select", { name: "organisationType", placeholder: "Condition logic", ref: register({ required: true }), className: 'lead-form-control' +
                                (errors.organisationType ? ' error' : '') },
                            React.createElement("option", { value: "" }, "Organisation type*"),
                            orgData.map(function (data, index) { return (React.createElement("option", { key: index, value: data.type }, data.type)); })),
                        errors.organisationType && React.createElement(ErrorMsg, null),
                        !!industrySector.length && (React.createElement(React.Fragment, null,
                            React.createElement("select", { name: "industrySector", className: 'lead-form-control' +
                                    (errors.industrySector ? ' error' : ''), title: "Knowing your nationality helps us to personalise our communications.", ref: register({ required: true }) },
                                React.createElement("option", { value: "" }, "Select a sub type*"),
                                industrySector.map(function (data, index) { return (React.createElement("option", { key: index, value: data }, data)); })),
                            errors.industrySector && React.createElement(ErrorMsg, null))),
                        React.createElement(ReCAPTCHA, { ref: recaptchaRef, size: "invisible", sitekey: "6LffiW0dAAAAALLrWPTv5ZzCDaI3CJ1yXzF26OfA" }),
                        submissionError && (React.createElement("div", { className: "submission-error-msg" },
                            React.createElement("i", { className: "error-icon" }),
                            React.createElement("p", null, "There was an issue with the submission"))),
                        React.createElement("button", { type: "submit" },
                            React.createElement("p", null, Loading && !submissionError && !successfulSubmission
                                ? 'Submitting...'
                                : 'Sign me up'),
                            React.createElement("div", { className: 'loading-bar' +
                                    (Loading && !submissionError && !successfulSubmission
                                        ? ''
                                        : 'hide') },
                                React.createElement("span", { className: "loader" }))),
                        React.createElement("small", null, "You can unsubscribe at any time"),
                        React.createElement("small", null,
                            "By submitting this form, I agree to Education New Zealand\u2019s",
                            ' ',
                            React.createElement("a", { href: "/privacy-policy", target: "_blank" }, "Privacy Policy"),
                            ' ',
                            "and to receiving information and updates from Education New Zealand.")))),
                successfulSubmission && (React.createElement("div", { className: "lead-form-animate" },
                    React.createElement("div", { className: "alert alert-success alert-small" },
                        React.createElement("p", null, "Thanks for subscribing")),
                    React.createElement("button", { className: "lead-form-close-btn", onClick: function () {
                            closeForm();
                        } }, "Close")))))));
};
export default LeadForm;
