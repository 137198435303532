(function ($) {
	var modal = $('#event-info-modal');
	var form = modal.find('form');
	var sending = modal.find('.event-form-sending');
	var success = modal.find('.event-form-success');
	var failure = modal.find('.event-form-failure');
	var submit = form.find('button');
	var fields = ['FirstName', 'LastName', 'Email', 'Organisation', 'Comments'];
	var compulsoryFields = ['FirstName', 'LastName', 'Email', 'Comments'];

	$('select.country-select').select2();
	$('.country-select').select2({
		placeholder: 'Market'
	});

	success.css('display', 'none');

	$(document).on('click', 'a.contact-form', function (e) {
		e.preventDefault();
		modal.modal();
		$('#Form_ContactForm_EventID').val($(this).attr('event-id'));
		//  2017.01.24  ENZ0921 - Add event title to submission..
		$('#Form_ContactForm_EventLabel').html($(this).attr('event-title'));
	});

	submit.on('click', function (e) {
		e.preventDefault();

		if (validate()) {
			form.css('display', 'none');
			sending.css('display', '');
			$.ajax({
				type: "POST",
				url: '/events/ContactForm',
				data: form.serialize(),
				success: function (result) {
					sending.css('display', 'none');
					success.css('display', '');
				},
				error: function (result) {
					sending.css('display', 'none');
					failure.css('display', '');
				}
			});
		}
	});

	modal.on('show.bs.modal', function (e) {
		reset();
	});

	$('[data-events-load-more]').on('click', function (e) {

		e.preventDefault();

		var params = {
			'start': $('#event-list-load-more a.btn').attr('data-start'),
			'date-from': $('#date-from').val(),
			'date-to': $('#date-to').val(),
			'industry-sector': $('#industry-sector').val(),
			'country': $('#country').val()
		};

		var link = '/events/loadmore';

		$.get(link, params, function (data) {

			if (data.start && data.content && data.lastItem) {
				$('#event-list-load-more a.btn').attr('data-start', data.start);
				$('#event-list').append(data.content);
				$('#event-last-item').html(data.lastItem);
			}

			if (!data.notLastPage) {
				$('#event-list').addClass('finished');
				$('#event-list-load-more').hide();
			}
		});
	});

	function validate() {
		var valid = true;
		for (var i = 0; i < compulsoryFields.length; i++) {
			var input = $('#Form_ContactForm_' + compulsoryFields[i]);
			if (input.val() == "") {
				$('#' + compulsoryFields[i] + '-error').html("This field is required.");
				$('#' + compulsoryFields[i] + '-error').show();
				valid = false;
			}
			else {
				$('#' + compulsoryFields[i] + '-error').hide();
			}
		}

		if ($('#Form_ContactForm_Email').val() && !isValidEmailAddress($('#Form_ContactForm_Email').val())) {
			$('#email-error').html("Must enter a valid email address.");
			$('#email-error').show();
			valid = false;
		}

		return valid;
	}

	function isValidEmailAddress(emailAddress) {
		var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
		return pattern.test(emailAddress);
	}

	function reset() {
		form.css('display', '');
		sending.css('display', 'none');
		success.css('display', 'none');
		failure.css('display', 'none');

		for (var i = 0; i < fields.length; i++) {
			var input = $('#Form_ContactForm_' + fields[i]);
			input.val('');
		}
	}
})(jQuery);
